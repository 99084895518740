import './BackToTopButton.scss';

import { Button } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

interface Props {
  backButtonLabel?: string;
}

const className = 'c-BackToTopButton';

const handleBackToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

const BackToTopButton = ({ backButtonLabel }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${className}__back-to-top-btn-container`}>
      <Button onClick={handleBackToTop} variant="secondary">
        {backButtonLabel ?? t('landing.backToTop', 'Back to Top')}
      </Button>
    </div>
  );
};

export default BackToTopButton;
